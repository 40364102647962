/* Video Carousel Container */
.fs-video-carousel {
    /* width: 100%;
    height: 100vh;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0px;
    background-color: transparent;
    position: relative;
    display: flex;
    flex-direction: column; */
    /* justify-content: center; Center the Slider vertically */
    background-color: rgba(0, 0, 0, 0.95);
    display: flex;
    flex-direction: column;
    height: 100dvh;
    margin: 0 auto;
    /* max-width: 1024px; */
    padding: 0;
    position: fixed;
    width: 100dvw;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1000;

}



/* Video Slide */
.fs-video-slide {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100vh;
    padding-bottom: 5%; /* Space for the product card */
}

/* Active Slide */
.slick-slide.slick-active.slick-center.slick-current {
    transform: scale(1.2) !important;
    z-index: 1 !important;
    opacity: 1.0 !important;
}


/* Inactive Slide */
.fs-video-carousel .slick-slide.slick-active:not(.slick-current) {
    z-index: -1 !important;
    opacity: 0.4;
    transform: scale(1.0) !important;

}

.slick-slide.slick-active:not(.slick-current) .video-slide .video-container .fs-video-wrapper .saro-video-overlay{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    overflow: hidden;
    opacity: 0.7;
    border-radius: 15px;
    z-index: 4;
    pointer-events: none;
}


/* General Slide Styling */
.slick-slide {
    position: relative; /* Ensure slides are positioned */
}

/* Video Title Container */
.video-title-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
    font-size: 1rem;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    color: white;
    position: absolute;
    top: 2%;
    left: 0%;
    z-index: 10;
}

.saro-carouselreels-video-title {
    margin-bottom: 0px;
    margin-left: 8px;
    margin-right: 8px;
    overflow: hidden; /* Hide overflowing content */
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Limits text to 2 lines */
        -webkit-box-orient: vertical;
        word-break: break-word; /* Ensures words break properly */
    
}

.title-icons {
    display: flex;
    justify-content: center;  /* Center horizontally */
    align-items: center;      /* Center vertically */
    gap: 10px;               /* Space between icons */
    z-index: 11;
}

/* Video Icons */
.video-icons {
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

/* this css was creating issue on live integration [Comment by Mustafa] */
/* .icon {
    cursor: pointer;
    font-size: 0.9rem;
    color: white;
} */

/* .icon:hover {
    opacity: 0.8;
} */

.menu-icon {
    font-size: clamp(2.0rem, 2rem, 2.5rem); /* Adjust size for menu icon */
    rotate: 90deg;
    max-width: 20px;
}

.speaker-icon {
    font-size: clamp(1.2rem, 1.5rem, 1.8rem);
    max-width: 25px;
}

.fullscreen-icon {
    font-size: clamp(1.2rem, 2.5rem, 1.8rem); /* Slightly larger for better visibility */
    max-width: 20px;
}


/* Video Container */
.video-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    
}

/* Video Wrapper */
.fs-video-wrapper {
    width: 100%;
    max-width: 475px;
    height: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    position: relative;
    overflow: hidden;
    aspect-ratio: 9 / 16;
}

/* Carousel Video */
.fs-carousel-video, .carousel-image {
    width: 100dvw;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
    cursor: pointer;
    aspect-ratio: 9 / 16;
}

.react-player {
    position: absolute;  /* Ensure it fits inside the wrapper */
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    border-radius: 15px;
}


/* Product Card Carousel */
.product-card-carousel {
    width: 67%;
    max-width: 475px;
    background: rgba(255, 0, 0, 0);
    padding: 0px;
    border-radius: 10px;
    position: absolute;
    bottom: 5%;
}

/* Product Card */
.saro-product-card {
    background: rgba(227, 130, 130, 0);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px;
    border-radius: 8px;
    width: 100%;
    max-width: 300px;
    gap: 0px;
}

/* Product Card Content */
.product-card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 300px;
    background: #444; /* Dark gray background */
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

/* Product Header */
.product-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 10px;
    background: #444;
    color: white;
    font-weight: bold;
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: flex-start;
    height: 60px;
    cursor: pointer;
}

/* Scrollable Content */
.fs-scrollable-content {
    background: rgb(255, 255, 255);
    max-height: 60vh; /* Ensures it does not exceed screen height */
    overflow-y: scroll;
    padding: 10px;
    flex-grow: 1;
    scrollbar-width: none; /* Hides scrollbar in Firefox */
    -ms-overflow-style: none; /* Hides scrollbar in Internet Explorer and Edge */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    border-radius: 10px;
}

/* Hide Scrollbar */
.fs-scrollable-content::-webkit-scrollbar {
    display: none; /* Hides the scrollbar */
}

.fs-scrollable-content::-webkit-scrollbar-thumb {
    background: #ccc; /* Scrollbar color */
    border-radius: 4px;
}

.fs-scrollable-content::-webkit-scrollbar-track {
    background: transparent; /* Keeps scrollbar track invisible */
}

/* Product Image */
.product-image {
    width: 25%;
    height: 45px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 8px;
}

/* Product Info */
.product-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    text-align: left;
}

/* Product Title */
.product-title {
    font-size: 0.8rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

/* Product Price */
.product-price {
    font-size: 0.8rem;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 600;
    color: #e0b066; /* Gold price color */
    text-align: left;
    margin: 0;
}

/* Product Actions */
.product-actions {
    display: flex;
    align-items: center;
}

/* Quantity Number */
.quantity-number {
    font-size: 1rem;
    font-weight: bold;
    color: white;
}

/* Checkout Button Container */
.checkout-btn-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

/* Checkout Button */
.checkout-btn {
    width: 100%;
    padding: 5px 10px;
    background: #ffc107;
    border: none;
    border-radius: 5px;
    color: black;
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
}

/* Checkout Button Hover */
.checkout-btn:hover {
    background-color: #f0cc17;
}

/* Modal */
.fs-product-modal {
    background: rgb(255, 255, 255);
    border-radius: 30px;
}

/* Modal Full Overlay */
.fs-product-modal.full-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    
}

.fs-product-modal .blur-overlay {
    position: fixed;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 97%;
    height: 97%;
    background: rgb(0, 0, 0); /* Semi-transparent background */
    z-index: -1; /* Ensure it's below the modal content */
    border-radius: 50px;
}

/* Modal Content */
.fs-modal-content {
    background: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 15px;
    /* max-width: 100%; */
    width: 100%;
    /* max-height: 90%; */
    overflow-y: auto;
    text-align: center;
    position: relative;
    height: 400px;
}

/* .product-modal.open ~ .slick-slider .slick-arrow {
    display: none !important;
} */



.modal-open .slick-prev, 
.modal-open .slick-next {
    display: none !important;
}
/* Close Button */
.close-button {
    position: absolute;
    background-color: black;
    color: white;
    border: none;
    border-radius: 100%;
    font-size: 1.5rem;
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    right: 10px;
    padding: 0;
    z-index: 1000;
}

/* Close Button Hover */
.close-button:hover {
    background-color: #333;
}

/* Modal Product Image */
.modal-product-image {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 10px;
}

/* Modal Product Title */
.modal-product-title {
    font-size: 1.0rem !important;
    font-weight: bold;
    color: black;
    margin-bottom: 0px;
    text-align: left;
}

/* Modal Product Price */
.modal-product-price {
    font-size: 0.8rem;
    font-weight: 600;
    color: #ff6600;
    margin-bottom: 10px;
    text-align: left;
}

/* Modal Product Description Heading */
.modal-product-description-heading {
    font-size: 1.0rem !important;
    font-weight: normal;
    color: black;
    margin-bottom: 10px;
    text-align: left;
}

.modal-product-description{
    font-size: 0.8rem;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 300;
    line-height: 1.5;
    color: #333;
    margin-bottom: 15px;
    text-align: left;
}


/* Shop Now Button */
.shop-now-modal-button {
    width: 100%;
    background: #e0b066;
    color: #333;
    font-size: 0.8rem;
    font-weight: normal;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
}

.shop-now-modal-button:hover {
    background-color: #e0a800;
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.shop-now-modal-button:active {
    background-color: #d29e00;
    transform: scale(0.98);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

    /* Hide the fullscreen button */
/* video::-webkit-media-controls-fullscreen-button {
    display: none !important;
} */

/* Hide the download button */
video::-webkit-media-controls-enclosure {
    overflow: hidden;    
}

/* video::-webkit-media-controls-panel {
    display: flex !important;
    visibility: visible !important;
} */

/* Hide Play/Pause button */
video::-webkit-media-controls-play-button {
    display: none !important;

}

/* Hide video duration (timestamp) */
video::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-time-remaining-display {
    display: none !important;
}

/* Hide the three-dot menu (More Options) */
video::-webkit-media-controls-panel {
    overflow: hidden !important;
}

/* Adjust the control panel so it's not pushed up */
video::-webkit-media-controls-panel {
    /* margin-bottom: 695px; 
    background: none !important; */
    display: none !important;
}
/* Optional: Adjust height of controls */
video::-webkit-media-controls-timeline {
    display: none !important;
}

/* video::-webkit-media-controls-mute-button:hover {
    display: none !important;
} */

.cart-actions {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
}

.quantity-btn {
    background: transparent;
    /* background-color: none; */
    border: none;
    font-size: 15px;
    cursor: pointer;
    color: black;
}

.quantity-btn:hover {
    color: #ffdf00;
}

.cart-quantity {
    font-weight: bold;
    font-size: 15px;
}

.video-top-blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 7%; /* Apply the effect to the top 10% of the video */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); 
    backdrop-filter: blur(1.5px); /* Apply the blur effect */
    z-index: 0; /* Ensure it's above the video */
    pointer-events: none; /* Prevent interaction */
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

/* Variant Selector */
.variant-selector {
    margin: 10px 0;
    text-align: left;
}

.variant-selector h3 {
    font-size: 0.9rem;
    font-weight: bold;
    color: black;
    margin-bottom: 5px;
    margin-top: 5px;
}

.variant-selector select {
    width: 100%;
    padding: 2px;
    font-size: 11px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: white;
    
}

/* When the user hovers over the dropdown */
.variant-selector select:hover {
    border-color: #999;
}

/* When the dropdown is focused */
.variant-selector select:focus {
    border-color: #e0b066; /* Gold accent */
    outline: none;
}

/* Disabled option */
.variant-selector option:disabled {
    color: #999;
}

/* Hide scrollbar inside the select dropdown on webkit browsers */
.variant-selector select::-webkit-scrollbar {
    display: none;
}

.color-swatch-container {
    display: flex;
    gap: 10px;
    margin-top: 5px;
}

.color-swatch {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: white;
    font-weight: bold;
    transition: 0.3s;
}

.color-swatch.selected {
    border: 2px solid black;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.share-menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.share-menu {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 300px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

.share-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}

.share-option {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: none;
    background: #007bff;
    color: white;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
}

.share-option:hover {
    background: #0056b3;
}

.share-cancel {
    background: #404040;
    color: white;
    padding: 10px;
    width: 100%;
    border: none;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.share-cancel:hover {
    background: #c82333;
}

.button-loader {
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 2px solid transparent;
    border-top: 2px solid black; /* Matches button text color */
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
    margin: 0 auto;
}

.play-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none; /* ✅ Ensures clicks pass through */
}

.play-icon {
    font-size: 40px;
    color: white;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


  .popup-menu {
    margin-top: -15px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background:linear-gradient(rgba(0, 0, 0, 0.4) 0%, rgb(0, 0, 0) 100%);
    display: flex;
    justify-content: center;
    border-radius: 20px;
    
  }

  .popup-inner-menu{
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    width: 90%; /* Adjust for responsiveness */
    max-width: 300px; /* Limit width */
    border-radius: 15px; /* Rounded corners */
    padding: 10px;
    text-align: center;
    
  }

  /* Popup content (centered buttons) */
  .popup-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    min-width: 200px;
    text-align: center;
  }

  /* Buttons */
  .popup-button {
    margin-top: 4px;
    background: #404040; /* Slightly lighter grey for buttons */
    color: white;
    border: none;
    padding: 12.5px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    
    transition: background 0.3s ease;
    width: 100%;
  }
  .b1{
    border-radius: 10px 10px 0px 0px;
  }
  .b2{
    border-radius: 0px 0px 10px 10px;
    
  }
  
  
  .popup-button:hover {
    background: #6A6A6A; /* Slightly lighter grey on hover */
  }

.copy-message {
    font-size: 14px;
    color: grey;
    margin-top: 5px;
    font-style: italic;
}

.notifier {
    margin-top: 10px;
    padding: 10px;
    background: rgba(0, 255, 0, 0.2);
    color: white;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    z-index: 999;
}

.next-arrow, .prev-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px; /* Reduce size */
    color: white;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 20px; /* Reduce width */
    height: 20px; /* Reduce height */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    transition: all 0.3s ease-in-out;
 
}

/* ✅ Left Arrow (Previous) */
.prev-arrow {
    left: 3px;
}

/* ✅ Right Arrow (Next) */
.next-arrow {
    right: 3px;
}

/* ✅ Hover Effect for Web */
.next-arrow:hover, .prev-arrow:hover {
    background: rgba(255, 255, 255, 0.7);
    color: black;
}

@media (min-width: 1024px) {
    .custom-prev-arrow,
    .custom-next-arrow {
        display: none !important;
    }

    .prev-arrow,
    .next-arrow {
        display: flex !important;
    }
}

/* Display custom arrows on mobile (up to 1024px) */
@media (max-width: 1024px) {
    .custom-prev-arrow,
    .custom-next-arrow {
        display: flex;
    }


    .video-carousel {
        width: 100%;
        height: 100vh;
        max-width: 930px;
        margin: 0 auto;
        padding: 0px;
        background-color: transparent;
        position: relative;
        display: flex;
        flex-direction: column;
        /* justify-content: center; Center the Slider vertically */
        
    }
}


/* Mobile View: For screens 768px and below */
@media (max-width: 768px) {
    .fs-modal-content {
        height: 60vh !important;
    }
    .video-carousel {
        width: 100vw;
        height: 75vh;
        margin: 0px;
        padding: 0px;
        background-color: transparent;
        justify-content: center;  
        background: transparent; 
        backdrop-filter: blur(15px); 
        -webkit-backdrop-filter: blur(15px); 
        transition: height 0.3s ease-in-out
    }

    .fs-carousel-video, .carousel-image {
        width: 100dvw;
        height: 75vh;
        object-fit: cover;
        border-radius: 0px;
        cursor: pointer;
    }

    .ios-fullscreen .video-carousel {
        height: 100dvh !important; /* Fix iOS fullscreen height issue */
    }
    
    /* ✅ Ensure the video fills the entire screen on iOS fullscreen */
    .ios-fullscreen .carousel-video, 
    .ios-fullscreen .carousel-image,
    .ios-fullscreen .fs-video-wrapper{
        height: 100dvh !important; /* 100dvh ensures full height on iOS */
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
       /* ✅ Fullscreen mode for mobile */
       .fs-video-wrapper:fullscreen,
       .fs-video-wrapper:-webkit-full-screen,
       .fs-video-wrapper:-moz-full-screen,
       .video-carousel:fullscreen,
       .video-carousel:-webkit-full-screen,
       .video-carousel:-moz-full-screen,
       .fs-carousel-video:fullscreen,
       .fs-carousel-video:-webkit-full-screen,
       .fs-carousel-video:-moz-full-screen {
           height: 100dvh !important;
       }
   
       /* ✅ Ensure video properly fits fullscreen */
       .mobile-fullscreen-mode .fs-video-wrapper,
       .mobile-fullscreen-mode .fs-carousel-video,
       .mobile-fullscreen-mode .video-carousel {
           height: 100dvh !important;
       }
   
       /* ✅ Prevent scrolling issues */
       .mobile-fullscreen-mode {
           background: black !important;
           overflow: hidden !important;
           position: fixed !important;
           top: 0;
           left: 0;
           width: 100vw;
           height: 100vh !important;
           z-index: 9999;
       }

    .video-top-blur {
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 7%; /* Apply the effect to the top 10% of the video */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); 
        backdrop-filter: blur(1.5px); /* Apply the blur effect */
        z-index: 0; /* Ensure it's above the video */
        pointer-events: none; /* Prevent interaction */
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    

    .fs-video-wrapper {
        width: 100vw;
        height: 75vh;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden; /* Prevents unwanted scrolling */
    }

    .show-in-mobile {
        display: flex !important;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9999;
        background: rgba(0, 0, 0, 0.6);
        padding: 10px 15px;
        font-size: 24px;
        color: white;
        cursor: pointer;
        border-radius: 50%;
        transition: opacity 0.2s ease-in-out;
    }

    .show-in-mobile:hover {
        background: rgba(0, 0, 0, 0.8);
    }

    .custom-prev-arrow.show-in-mobile {
        left: 10px;
    }

    .custom-next-arrow.show-in-mobile {
        right: 10px;
    }
   
    .video-container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: auto;
        /* background-color: #007bff; */
    }
 /* Apply fullscreen styles properly */
.fullscreen-mode {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: black;
    z-index: 9999;
    overflow: hidden;
}


    video::-webkit-media-controls-panel {
        /* margin-bottom: 695px; 
        background: none !important; */
        display: none !important;
    }

/* Custom Arrow Styling */
.custom-prev-arrow,
.custom-next-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px; /* Reduce size */
    color: white;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 30px; /* Reduce width */
    height: 30px; /* Reduce height */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    transition: all 0.3s ease-in-out;
}

.prev-arrow,
.next-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px; /* Reduce size */
    color: white;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 22px; /* Reduce width */
    height: 22px; /* Reduce height */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    transition: all 0.3s ease-in-out;
}

.slick-slide.slick-active.slick-center.slick-current {
    transform: scale(1.2) !important;
    z-index: 1 !important;
    opacity: 1.0 !important;
}


/* .fullscreen-active {
    width: 100vw !important;
    height: 100vh !important;
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 9999 !important;
    background: black !important;
}


.fullscreen-active .carousel-video {
    width: 100vw !important;
    height: 100vh !important;
    object-fit: cover !important;
    border-radius: 0 !important;
} */

/* ✅ Prevent scrolling when fullscreen */
.mobile-fullscreen-mode {
    overflow: hidden !important;
    position: fixed !important;
    width: 100vw;
    height: 100vh;
    background: black !important;
}
.custom-prev-arrow {
    left: 10px; /* Adjust positioning */
}

.custom-next-arrow {
    right: 10px; /* Adjust positioning */
}

.prev-arrow {
    left: 3px; /* Adjust positioning */
}

.next-arrow {
    right: 3px; /* Adjust positioning */
}

/* Hover effect */
.custom-prev-arrow:hover,
.custom-next-arrow:hover {
    background: rgba(255, 255, 255, 0.7);
    color: black;
}

.prev-arrow:hover,
.next-arrow:hover {
    background: rgba(255, 255, 255, 0.7);
    color: black;
}

.video-title-container {
    display: flex;
    align-items: flex-start; /* Centers all elements vertically */
    justify-content: space-between; /* Distributes elements */
    width: 100%;
    padding: 0 20px;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    color: white;
    position: absolute;
    top: 2%;
    left: 0%;
    z-index: 10;
    gap: 2%;
}

.saro-carouselreels-video-title {
    flex-grow: 1;  /* Allows title to take available space */
    margin-bottom: 0px;
    font-size: clamp(1.0rem, 2rem, 1.2rem);
    font-weight: 600; /* Make it slightly bold for visibility */
    text-align: left; /* Keep text left-aligned */
    overflow: hidden; /* Hide overflowing content */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Limits text to 2 lines */
    -webkit-box-orient: vertical;
    word-break: break-word; /* Ensures words break properly */
}

    
/* .video-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    
} */
.product-card-carousel {
    width: 70%;
    max-width: 475px;
    background: rgba(255, 0, 0, 0);
    padding: 0px;
    border-radius: 10px;
    position: absolute;
    bottom: 5%;
}

.product-title {
    font-size: 1.15rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    color: #ccc;
}

.product-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 10px;
    background: #444;
    color: white;
    font-weight: bold;
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: flex-start;
    height: 80px;
}

.product-image {
    width: 28%;
    height: 65px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 8px;
    /* max-height:65px ; */
}

.product-price {
    font-size: 0.9rem;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 600;
    color: #e0b066; /* Gold price color */
    text-align: left;
    margin: 0;
}

.shop-now-modal-button {
    width: 100%;
    background: #e0b066;
    color: #333;
    font-size: 1.1rem;
    font-weight: normal;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
}
.video-slide {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* ✅ Center the videos properly */
    height: 100vh; /* ✅ Ensure full height */
    padding: 0;  /* ✅ Remove unnecessary padding */
}

.play-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none; /* ✅ Ensures clicks pass through */
}

.play-icon {
    font-size: 40px;
    color: white;
}

.speaker-icon {
    font-size: clamp(1.2rem, 1.5rem, 1.8rem);
    max-width: 25px;
}

.fullscreen-icon {
    font-size: clamp(2.0rem, 2.5vw, 2.8rem); /* Slightly larger for better visibility */
}
/* ✅ Fullscreen Simulation for iOS */
.ios-fullscreen {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw !important;
    height: 100vh !important;
    background: black !important;
    overflow: hidden !important;
    z-index: 9999;
}

/* ✅ Ensure the video fills the entire screen */
.ios-fullscreen .video-container {
    width: 100vw !important;
    height: 100vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
}

/* ✅ Make sure the video is centered and covers the screen */
.ios-fullscreen video {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

/* ✅ Hide other elements when in fullscreen mode */
/* .ios-fullscreen .video-title-container,
.ios-fullscreen .product-card-carousel, */
.ios-fullscreen .popup-menu {
    display: none !important;
}

/* ✅ Exit Fullscreen Button */
.ios-fullscreen .exit-fullscreen-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 20px;
    color: white;
    background: rgba(0, 0, 0, 0.6);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 10000;
}

}

@media screen and (max-width:500px) {
    .popup-menu{
        height: 100vh !important;  
        border-radius: 0px;  
    }    
    
}





/* ------------------------ */

