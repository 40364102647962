/* this css was creating issue on live integration [Comment by Mustafa] */
/* *body {
  font-family: 'Asap', sans-serif;
} */

/* .container {
    display: flex; /* Use flexbox for layout */
    /* justify-content: center;  Space items evenly between the two columns 
    margin-top: -20px;
  } */

.headers1 {
  font-size: 20px;
  margin-top: 40px;
  font-family: 'Asap', sans-serif;
  font-weight: bolder;
}

.Headerpara1 {
  font-family: 'Asap', sans-serif;
}

/* Rating Section */
.ratingStar {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
}

.rate {
  float: left;
  height: 46px;
  padding: 0 100px;

}

.rate:not(:checked) > input {
  position: absolute;
  top: -9999px;
}

.rate:not(:checked) > label {
  float: right;
  width: 1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 30px;
  color: #ccc;
}

.rate:not(:checked) > label:before {
  content: '★ ';
}

.rate > input:checked ~ label {
  color: #FF7D00;
}

.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #FF7D00;
}

.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #FF7D00;
}

/* Comment Box */
.comment-textarea {
  width: 100% !important;
  min-height: 130px !important;
  background-color: #D9D9D9 !important;
  color: #646464 !important;
  padding: 10px !important;
  border: none !important;
  border-radius: 5px !important;
  resize: vertical !important;
}

.saro-rating-page {
  padding: 0px 70px !important;
  width: var(--containerWidth) !important;
  height: 550px;
}

/* Buttons Section */
.btn-div {
  display: flex;
  justify-content: center;
  width: 100%; /* Adjust width for alignment */
  padding: 20px 0; /* Padding added for space */
}



/* Footer Section */
.footer-container {
  margin-top: 100px; /* Adjusted margin to move the footer down */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 12px;
}

.footer-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logoH {
  width: 35px; /* Slightly increased logo size */
  height: auto;
}

/* Error Message */
.error {
  color: red;
  font-size: 12px;
}

.powered-byH {
  font-size: 10px; /* Slightly larger font size */
  margin-right: 5px;
  margin-left: 5px ;
}

/* Mobile styles */
@media (max-width: 768px) {
  .saro-rating-page {
    height: 600px;
    padding: 0 20px;
  }

  .headers1 {
    margin-top: 20px;
  }

  .ratingStar {
    flex-direction: column;
    padding-top: 0;
  }

  .btn-div {
    flex-direction: column; /* Stacks buttons vertically for mobile view */
    align-items: center;
  }

  .bb1, .bb1Outline {
    width: 100%;
    margin: 10px 0; /* Ensure spacing between buttons in mobile view */
  }

  .rate {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px; /* Ensure the height is still kept */
    padding: 0 10px;
  }

  .powered-byH {
    font-size: 10px; /* Slightly larger font size */
    margin-right: 5px;
    margin-left: 5px ;
  
  
}
.footer-logoH {
  width: 35px; /* Slightly increased logo size */
  height: auto;
}
  
}
