.fs{
    width: 104dvw !important;
    height: 100dvh !important;
}

.x-icon{
    font-size: 50%;
}

/* Video Carousel Container */
.video-carosel-fs {
    width: 100dvw;
    height: 100dvh;
    /* max-width: 1024px; */
    margin: 0 auto;
    padding: 0px;
    background-color: black;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top:0;
    display: flex;
    flex-direction: column;
    z-index: 1000;

    /* justify-content: center; Center the Slider vertically */
    
}

/* Video Slide */
.video-slide-fs {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100dvh;
    padding-bottom: 5%; /* Space for the product card */
}

.video-container-fs {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100dvh;
    
}

.video-wrapper-fs {
    width: 100%;
    max-width: 475px;
    /* height issue solved here */
    height: 100dvh !important;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: start;
    position: relative;
    overflow: hidden;
    background: transparent;
    

    /* ✅ Add subtle gray border */
    
    /* border-radius: 10px; */
}

.video-top-blur-fs {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100dvh;
    height: 7%; /* Apply the effect to the top 10% of the video */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); 
    backdrop-filter: blur(1.5px); /* Apply the blur effect */
    z-index: 0; /* Ensure it's above the video */
    pointer-events: none; /* Prevent interaction */
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.carousel-video-fs {
    width: 100%;
    height: 100dvh; 
    object-fit: cover;
    
    /* border-radius: 15px; */
    cursor: pointer;
    aspect-ratio: 9 / 16;
    background-color: transparent;
    opacity: 0;
    transition: opacity 0.5s ease-in-out; /* Smooth fade-in */
}

.carousel-video-fs.loaded {
    opacity: 1;
}

/* Video Title Container */
.v-t-c {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    font-size: 1rem;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    color: white;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 10;
}

/* Modal */
.saro-carouselfullscreen-product-modal {
    background: rgb(255, 255, 255);
    border-radius: 10px;
    width: 100%;
    padding: 0;
    display: block;
}

.video-title-fs {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    align-items: center;
    display: -webkit-box;
    font-size: clamp(1.1rem, 2rem, 1.2rem);
    margin-left: 8px;
    margin-top: 8px;
    margin-right: 8px;
    overflow: hidden;
    justify-content: flex-end;
    word-break: break-word;
    align-content: flex-end;
    flex-wrap: wrap;
    margin-bottom: 8px !important;
}

/* Modal Content */
.saro-carouselfullscreen-modal-content {
    background: rgb(255, 255, 255);
    padding: 20px;
    border-radius: 10px;
    /* max-width: 100%; */
     max-width: 100%;
    /* max-height: 90%; */
    overflow-y: auto;
    text-align: center;
    position: relative;
    height: auto;
    margin: 0;
}

.scrollable-content {
    max-height: 60vh; /* Ensures it does not exceed screen height */
    overflow-y: scroll; /* Enables vertical scrolling */
    padding: 10px; /* Adds some spacing inside */
    scrollbar-width: none; /* Hides scrollbar in Firefox */
    -ms-overflow-style: none; /* Hides scrollbar in Internet Explorer and Edge */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

/* For Webkit Browsers (Chrome, Safari) */
.scrollable-content::-webkit-scrollbar {
    display: none;
}

.scrollable-content::-webkit-scrollbar-thumb {
    background: #ccc; /* Scrollbar color */
    border-radius: 4px;
}

.scrollable-content::-webkit-scrollbar-track {
    background: transparent; /* Keeps scrollbar track invisible */
}

.saro-carouselfullscreen-product-animation {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.saro-carouselfullscreen-product-animation.show {
    opacity: 1;
    transform: translateY(0);
}


/* Display custom arrows on mobile (up to 1024px) */
@media (max-width: 1024px) {
    .custom-prev-arrow,
    .custom-next-arrow {
        display: flex;
    }


    .video-carosel {
        width: 100%;
        height: 100vh;
        max-width: 930px;
        margin: 0 auto;
        padding: 0px;
        background-color: transparent;
        position: relative;
        display: flex;
        flex-direction: column;
        /* justify-content: center; Center the Slider vertically */
        
    }
}

/* Mobile View: For screens 768px and below */
@media (max-width: 768px) {

    /* .custom-next-arrow.show-in-mobile {
        right: 25px !important;
    } */

    
    .video-carosel {
        width: 100dvw;
        height: 100dvh;
        position: fixed;
        bottom: 0;
        z-index: 5;
        margin: 0px;
        padding: 0px;
        background-color: transparent;
        
        background: transparent; 
        backdrop-filter: blur(15px); 
        -webkit-backdrop-filter: blur(15px); 
        transition: height 0.3s ease-in-out
    }

    .v-t-c {
        display: flex;
        align-items: center; /* Centers all elements vertically */
        justify-content: space-between; /* Distributes elements */
        width: 100%;
        padding: 0 20px 0px 20px;
        font-family: 'Roboto', Arial, Helvetica, sans-serif;
        color: white;
        position: absolute;
        top: 1%;
        left: 0%;
        z-index: 10;
        gap: 2%;
    }

    .slick-current .video-wrapper{
        z-index: 10;
    }
    .slick-current .title-icons {
        gap: 20px;
        
    }

    .product-card-carousel-fs {
        width: 70%;
        max-width: 475px;
        background: rgba(255, 0, 0, 0);
        padding: 0px;
        border-radius: 10px;
        position: absolute;
        bottom: 5%;
        display: block !important;
    }

    .blurred-fs {
        filter: blur(10px);
        transition: filter 0.3s ease-out;
    }
    .cart-icon-wrapper {
        position: relative;
        display: inline-block;
        cursor: pointer;
        width: 40px; /* Set consistent size */
        height: 40px; /* Set consistent size */
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .cart-icon {
        font-size: 24px; /* Adjust icon size */
        color: #000; /* Icon color */
    }
    
    .cart-quantity-badge {
        position: absolute;
        top: 3px;
        right: -4px;
        background-color: #ffc107; /* Red badge background */
        color: #fff; /* White text */
        border-radius: 50%;
        padding: 5px 5px;
        font-size: 12px; /* Adjust badge font size */
        font-weight: bold;
    }
    
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    
    .cart-loader {
        width: 20px;
        height: 20px;
        border: 2px solid #000; /* Black loader */
        border-top: 2px solid #fff; /* White spinning segment */
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }
    
    
}