 


.video-call-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.shopable-videos-container {
  
  /* bottom: 20px;
  right: 20px; */
  background-color:rgba(0, 0, 0, 0);
  border-radius: 10px;
  /* padding: 20px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  max-width: 100dvw;
  overflow: hidden;
  /* height: 100vh; */
  height: 100%;
  width: 100%;
  
}

/* .container1 {
  width: auto !important;  
  max-width: 100% !important;
  height: auto; 
} */





.modal2{
  /* Add your custom styles here */
  /* For example: */
  width: 100%;
  height: 100%;
  padding: 0px !important;
  margin-left: 0px;
  border: 2px;
  border-radius: 10px;

  /* background-color: rgba(0, 0, 0, 0.5); */

}

.modal3{
  /* Add your custom styles here */
  /* For example: */
  width: 100%;
  height: 100%;
  padding: 0px !important;
  margin-left: 0px;
  border: 2px;
  border-radius: 10px;

  background-color:transparent;
  backdrop-filter: blur(10px); /* Apply blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
}


.video-call-gif {
  position: fixed;
  bottom: 20px; /* Adjust position as needed */
  right: 20px; /* Adjust position as needed */
  width: 80px; /* Adjust the width as needed */
  height: auto;
  z-index: 999; /* Ensure the GIF is on top of other content */
}

.close-button {
  margin-top: 10px;

}

.close1 {
 font-size: 50px;
 margin-left: 96%;
 margin-top: -2%;
}

/* Media query for screens with a maximum width of 768 pixels (typical for mobile devices) */
@media (max-width: 768px) {
  .video-call-button {
    display: none; /* Hide the video call button on mobile devices */
  }

  .wimg {
    width: 100%; /* Set the image width to 100% for mobile responsiveness */
    height: auto; /* Ensure the aspect ratio is maintained */
  }

  .popup {
    right: 20px; /* Adjust the position of the popup for mobile layout */
  }
  .modal2{
    overflow: hidden !important;
  }

  .shopable-videos-container {
  
    /* bottom: 20px;
    right: 20px; */
    background-color: transparent;
    border-radius: 0px;
    padding: 10px;
    /* box-shadow: 0 4px 8px rgb(255, 255, 255); */
    z-index: 1000;
    /* max-width: 100vw; */
    /* overflow: hidden; */
    max-height: 40vh;
    
    
  }

}
