/* body {
    font-family: 'Asap', sans-serif;
  }
   */
  /* .container {
      display: flex; /* Use flexbox for layout */
      /* justify-content: center;  Space items evenly between the two columns 
      margin-top: -20px;
    } */

  .headers1 {
    font-size: 20px;
    margin-top: 40px;
    font-family: 'Asap', sans-serif;
    font-weight: bolder;
  }
  
  .Headerpara1 {
    font-family: 'Asap', sans-serif;
  }
  
  /* Rating Section */
  .ratingStar {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
  }
  
  .rate {
    float: left;
    height: 46px;
    padding: 0 100px;
  
  }
  
  .rate:not(:checked) > input {
    position: absolute;
    top: -9999px;
  }
  
  .rate:not(:checked) > label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 30px;
    color: #ccc;
  }
  
  .rate:not(:checked) > label:before {
    content: '★ ';
  }
  
  .rate > input:checked ~ label {
    color: #FF7D00;
  }
  
  .rate:not(:checked) > label:hover,
  .rate:not(:checked) > label:hover ~ label {
    color: #FF7D00;
  }
  
  .rate > input:checked + label:hover,
  .rate > input:checked + label:hover ~ label,
  .rate > input:checked ~ label:hover,
  .rate > input:checked ~ label:hover ~ label,
  .rate > label:hover ~ input:checked ~ label {
    color: #FF7D00;
  }
  
  /* Comment Box */
  .comment-textarea {
    width: 100% !important;
    min-height: 130px !important;
    background-color: #D9D9D9 !important;
    color: #646464 !important;
    padding: 10px !important;
    border: none !important;
    border-radius: 5px !important;
    resize: vertical !important;
  }
  
  .saro-rating-page {
    padding: 0px 70px !important;
    width: var(--containerWidth) !important;
    height: 550px;
  }
  
  /* Buttons Section */
  .btn-div {
    display: flex;
    justify-content: center;
    width: 100%; /* Adjust width for alignment */
    padding: 20px 0; /* Padding added for space */
  }
  
  
  
  /* Footer Section */
  .footer-container {
    margin-top: 100px; /* Adjusted margin to move the footer down */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 12px;
  }
  
  .footer-linkB {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .footer-logoB {
    width: 35px; /* Slightly increased logo size */
    height: auto;
  }
  
  /* Error Message */
  .error {
    color: red;
    font-size: 12px;
  }

  .powered-byB {
    font-size: 10px; /* Slightly larger font size */
    margin-right: 5px;
    margin-left: 5px ;
}
  
@media screen and (max-width: 768px) {
  /* Adjust font size for headers */
  /* this css was creating issue on live integration [Comment by Mustafa] */
  /* .headers1 {
      font-size: 16px;
      margin-top: 10px;
  } */

  /* Rating Section Adjustments */
  .ratingStar {
      padding-top: 10px;
      flex-direction: column;
      gap: 5px; /* Add gap between elements */
  }

  /* Comment Box */
  .comment-textarea {
      min-height: 80px !important; /* Further reduced height for smaller screens */
      padding: 8px !important;
  }

  /* Footer Adjustments */
  .footer-containerb {
      margin-top: 20px;
      /* flex-direction: column; */
      /* height: auto; */
   
  }
  .powered-byB{
    font-size: 12px;
  }
  
  .footer-logoB {
      width: 35px;
  }

  /* Button Section */
  .btn-div {
      padding: 10px 0;
  }

  /* Container */
  .saro-rating-page {
      padding: 0 10px !important;
      width: 100% !important;
      height: 550px;
      margin-top: 20px; /* Add some top margin */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      justify-content: space-between;
      padding-bottom: 20px;
  }
}